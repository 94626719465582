import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="diabetes" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Diabetes Typ II / Metabolisches Syndrom`}</h1>
      <h3 {...{
        "id": "eine-weltweite-massenerkrankung-als-epidemie-des-21-jahrhunderts"
      }}>{`Eine weltweite Massenerkrankung als „Epidemie des 21. Jahrhunderts“?`}</h3>
      <p>{`Genetische Veranlagung, Bewegungsmangel und unsere moderne (Fehl-) Ernährung mit reichlich isolierten Kohlenhydraten / Zucker, Fett und chemisch veränderten Nahrungsmitteln (Zusatzstoffe) sind ursächlich verantwortlich für die Entwicklung des Diabetes Typ II, erhöhte Blutfettwerte, Bluthochdruck und Übergewicht (= Metabolisches Syndrom).`}</p>
      <p>{`Das Bauchspeicheldrüsenhormon Insulin sorgt normalerweise dafür, dass der Zucker (Glucose) als Hauptenergiespender aus dem Blut in die Körperzellen gelangt. Eine zunehmende Störung der Zuckeraufnahme durch die Körperzellwände (Insulinresistenz) führt zu erhöhten Blutzuckerwerten, die hochgradig gefäßschädigend wirken und entsprechende Folgeerkrankungen provozieren:`}</p>
      <p>{`So führt Diabetes und Bluthochdruck in Verbindung mit Fettstoffwechselstörungen nicht nur zu Gefäßschäden, die sich an den Augen oder den Nieren, durch Nervenschmerzen sowie Wundheilungsstörungen (Diabetische Geschwüre / „offenes Bein“) bemerkbar machen, sondern durch arteriosklerotische Gefäßschäden („Verkalkung“) können auch Schlaganfall und Herz-Infarkt ausgelöst werden.`}</p>
      <p>{`Die schleichende Zunahme der Insulinresistenz durchläuft verschiedene Stadien, in denen der Nüchtern-Blutzuckerwert noch lange normal bleibt und erst im letzten Stadium erhöht ist:`}</p>
      <h4 {...{
        "id": "1-stadium"
      }}><strong parentName="h4">{`1. Stadium:`}</strong></h4>
      <p>{`beginnende Insulin-Resistenz der Körperzellen führt zu Mehrausschüttung von Insulin aus der Bauchspeicheldrüse; der Nüchtern-Blutzucker-Wert ist normal`}</p>
      <h4 {...{
        "id": "2-stadium"
      }}><strong parentName="h4">{`2. Stadium:`}</strong></h4>
      <p>{`die Bauchspeicheldrüse ist überfordert (Insulin vermindert); der Nüchtern-Blutzucker-Wert ist zwar noch normal, aber durch die gestörte Glukoseverwertung ergeben sich ztw. durchaus deutlich erhöhte Blutzuckerwerte, die auch schon die Blutgefäßwände schädigen können!`}</p>
      <h4 {...{
        "id": "3-stadium"
      }}><strong parentName="h4">{`3. Stadium:`}</strong></h4>
      <p>{`über Jahre entwickelt sich ein ausgeprägter Diabetes durch Zunahme der Resistenz u/o Erschöpfung der Bauchspeicheldrüse; der Blutzucker ist ständig erhöht und kann ab einem bestimmten Schwellenwert auch im Urin nachgewiesen werden.`}</p>
      <hr></hr>
      <p>{`Die naturheilkundliche Therapie des Typ-2-Diabetes im Rahmen eines Metabolischen Syndroms erstreckt sich im Wesentlichen über drei Bereiche:`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/beschwerdebilder/vorsorge_praevention"
          }}>{`Vorsorge / Prävention`}</a>{` durch Bewegung, Gewichtsreduktion, vollwertige Ernährung, Stoffwechsel-Optimierung`}</li>
        <li parentName="ul">{`individuelle Ernährungsberatung, Verordnung von geeigneten Vitalstoffen (spez. Mineralien, Vitamine, Spurenelemente…), `}<a parentName="li" {...{
            "href": "/therapien/darmsanierung"
          }}>{`Darmsanierung`}</a></li>
      </ul>
      <p><strong parentName="p">{`Nur ein umfassender Gesamteindruck ermöglicht ein individuell erfolgreiches Therapie-Paket!`}</strong></p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      